import type { DeepPartial } from "@/global"
import { useMemo } from "react"
import type { I18nLocaleTextBundle } from "./types"
import { _exists, _tHtml, _translate } from "./utils"

/**
 * to be used in client components
 */
export const useTranslation = <T extends I18nLocaleTextBundle>(
  messages: T,
  overwriteMessages?: DeepPartial<T>,
) => {
  const t = useMemo(
    () => _translate(messages, overwriteMessages),
    [messages, overwriteMessages],
  )
  const tHtml = useMemo(() => _tHtml(t), [t])
  const exists = useMemo(() => _exists(messages), [messages])

  return { t, tHtml, exists }
}

/**
 * to be used in utility functions
 */
export const getTranslation = <T extends I18nLocaleTextBundle>(
  messages: T,
  overwriteMessages?: DeepPartial<T>,
) => {
  const t = _translate(messages, overwriteMessages)
  const tHtml = _tHtml(t)
  const exists = _exists(messages)

  return { t, tHtml, exists }
}
