/**
 * feature flags
 */
export const FEATURE_FLAG_ENABLE_GROUPS = false;
export const FEATURE_FLAG_ENABLE_REGISTRATION = false;
export const FEATURE_FLAG_ENABLE_UNFINISHED_FEATURES = false;
export const isBrowser = () => typeof window !== "undefined";
export const isServer = () => typeof window === "undefined";
export const isDevelopment = () => process.env.NODE_ENV === "development";
export const isStaging = () => 
// TODO: staging domain should be an env variable
isBrowser() && window.location.hostname === "app.rightflow.app";
export const isProduction = () => process.env.NODE_ENV === "production";
export const isTest = () => process.env.NODE_ENV === "test";
export const getDomain = () => typeof window !== "undefined"
    ? window.location.origin
    : process.env.HOST_DOMAIN;
export const NEXT_PUBLIC_HELP_CENTER_URL = "https://support.rightflow.de";
/** custom app env */
export const NEXT_PUBLIC_APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;
/** Azure */
export const AZURE_DOCUMENT_INTELLIGENCE_API_KEY = process.env.AZURE_DOCUMENT_INTELLIGENCE_API_KEY;
export const AZURE_DOCUMENT_INTELLIGENCE_ENDPOINT = process.env.AZURE_DOCUMENT_INTELLIGENCE_ENDPOINT;
export const AZURE_OPENAI_ENDPOINT = process.env.AZURE_OPENAI_ENDPOINT;
export const AZURE_OPENAI_API_KEY = process.env.AZURE_OPENAI_API_KEY;
export const AZURE_SEARCH_ENDPOINT = process.env.AZURE_SEARCH_ENDPOINT ?? "";
export const AZURE_SEARCH_EMBEDDINGMODEL_ENDPOINT = process.env.AZURE_SEARCH_EMBEDDINGMODEL_ENDPOINT;
export const AZURE_SEARCH_EMBEDDINGMODEL_API_KEY = process.env.AZURE_SEARCH_EMBEDDINGMODEL_API_KEY;
export const AZURE_SEARCH_API_KEY = process.env.AZURE_SEARCH_API_KEY;
export const AZURE_MAPS_API_KEY = process.env.AZURE_MAPS_API_KEY;
export const AZURE_STORAGE_CONNECTION_STRING = process.env.AZURE_STORAGE_CONNECTION_STRING;
export const AZURE_EMAIL_CONNECTION_STRING = process.env.AZURE_EMAIL_CONNECTION_STRING ?? "";
/** Application Settings and Default */
const numberOrDefault = (value, defaultValue) => {
    if (value !== undefined) {
        const parsed = Number.parseInt(value);
        if (!Number.isNaN(parsed)) {
            return parsed;
        }
    }
    return defaultValue;
};
/**
 * Global settings for the application
 *
 * These settings are used as defaults for the application:
 * - *defaultWorkfowScheduleDelay*: The default delay for scheduling a workflow run
 * - *defaultPaginationLimit*: The default limit for paginated results
 */
const platformEmailDomain = process.env.PLATFORM_EMAIL_DOMAIN || "rightflow.de";
export const GlobalSettings = {
    defaultWorkfowScheduleDelay: numberOrDefault(process.env.DEFAULT_WORKFLOW_SCHEDULE_DELAY, 1000 * 60 * 5),
    defaultPaginationLimit: numberOrDefault(process.env.DEFAULT_PAGINATION_LIMIT, 10),
    logging: {},
    email: {
        platformEmailDomain,
        // TODO: change, once azure limit is increased
        systemEmailAddress: `noreply@${platformEmailDomain}`,
        defaultSubject: "Nachricht",
        sendMessages: process.env["SEND_MESSAGES"] === "true",
        customEmailSyncMinutes: 5,
        minimumEmailSyncDelaySeconds: 10,
    },
    delays: {
        defaultSignatureRequestAccessTokenExpiryDelay: 1000 * 60 * 60 * 24 * 2, // 2 days,
    },
    healthchecksIoPingKey: process.env.HEALTHCHECKS_IO_PING_KEY,
    usage: {
        natifai: {
            limit: process.env.NATIF_AI_DAILY_USAGE_LIMIT
                ? Number.parseInt(process.env.NATIF_AI_DAILY_USAGE_LIMIT)
                : 100,
        },
    },
    documentProcessing: {
        currentVersion: 2,
    },
    fileSizeLimit: 1024 * 1024 * 50, // 50MB
};
/**
 * These DAILY limits are just here to prevent accidental overuse of the services, and are therefore set quite high.
 * They can also be set via environment variables to quickly increase or decrease the limits.
 *
 * Limits:
 * | Service                                        | Quantity      | Cost   |
 * |------------------------------------------------|---------------|--------|
 * | azure-document-intelligence-classification     |      20,000   | ~$60   |
 * | azure-document-intelligence-extraction-invoice |       5,000   | ~$50   |
 * | azure-document-intelligence-extraction-prebuilt|       5,000   | ~$50   |
 * | azure-document-intelligence-layout             |       5,000   | ~$50   |
 * | azure-document-intelligence-ocr                |      20,000   | ~$30   |
 * | azure-openai-gpt-4o (input equivalent)         |  20,000,000   | ~$50   |
 * | azure-openai-gpt-4o-mini (input equivalent)    | 100,000,000   | ~$15   |
 * | workflow-step                                  |      10,000   |   $0   |
 * | case                                           |         200   |   $0   |
 */
export const GlobalUsageLimits = {
    "azure-document-intelligence-classification": process.env
        .LIMIT_DOCUMENT_INTELLIGENCE_CLASSIFICATION
        ? Number.parseInt(process.env.LIMIT_DOCUMENT_INTELLIGENCE_CLASSIFICATION)
        : 20000,
    "azure-document-intelligence-extraction-invoice": process.env
        .LIMIT_DOCUMENT_INTELLIGENCE_EXTRACTION_INVOICE
        ? Number.parseInt(process.env.LIMIT_DOCUMENT_INTELLIGENCE_EXTRACTION_INVOICE)
        : 5000,
    "azure-document-intelligence-layout": process.env
        .LIMIT_DOCUMENT_INTELLIGENCE_LAYOUT
        ? Number.parseInt(process.env.LIMIT_DOCUMENT_INTELLIGENCE_LAYOUT)
        : 5000,
    "azure-document-intelligence-ocr": process.env.LIMIT_DOCUMENT_INTELLIGENCE_OCR
        ? Number.parseInt(process.env.LIMIT_DOCUMENT_INTELLIGENCE_OCR)
        : 20000,
    "azure-openai-gpt-4o": process.env.LIMIT_OPENAI_GPT_4O
        ? Number.parseInt(process.env.LIMIT_OPENAI_GPT_4O)
        : 20000000,
    "azure-openai-gpt-4o-mini": process.env.LIMIT_OPENAI_GPT_4O_MINI
        ? Number.parseInt(process.env.LIMIT_OPENAI_GPT_4O_MINI)
        : 100000000,
    "workflow-step": process.env.LIMIT_WORKFLOW_STEP
        ? Number.parseInt(process.env.LIMIT_WORKFLOW_STEP)
        : 10000,
    "azure-document-intelligence-extraction-prebuilt": process.env
        .LIMIT_DOCUMENT_INTELLIGENCE_EXTRACTION_PREBUILT
        ? Number.parseInt(process.env.LIMIT_DOCUMENT_INTELLIGENCE_EXTRACTION_PREBUILT)
        : 5000,
    case: process.env.LIMIT_CASE ? Number.parseInt(process.env.LIMIT_CASE) : 200,
};
