"use client"

import { Stack } from "ui/dist/layout/Stack"
import { useTranslation } from "@/i18n"
import de from "@/app/_messages/de.json"
import dynamic from "next/dynamic"

const UnexpectedError = dynamic(() => import("./_components/UnexpectedError"))

interface ErrorProps {
  error: Error & { digest?: string }
  reset: () => void
}

export default function GlobalError({ error, reset }: ErrorProps) {
  const { t } = useTranslation(de)

  function reloadWindow() {
    window.location.href = "/"
    // @ts-expect-error - reload is a valid method, true will skip browser cache
    window.location.reload(true)
  }

  return (
    <Stack
      height="100vh"
      width="100vw"
      alignItems="center"
      justifyContent="center"
      sx={{
        bgcolor: (theme) => theme.palette.background.default,
      }}
    >
      <UnexpectedError
        title={t("globalError.title")}
        message={t("globalError.message")}
        variant="not-found"
        error={error}
        reset={reloadWindow}
      />
    </Stack>
  )
}
