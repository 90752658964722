export function sanitizeHtml(
  message: string,
  {
    allowedTags,
    allowedAttributes,
  }: {
    allowedTags: string[]
    allowedAttributes: Record<string, string[]>
  },
  document?: Document,
) {
  const doc = document ?? new DOMParser().parseFromString(message, "text/html")

  const Node = {
    ELEMENT_NODE: 1,
    TEXT_NODE: 3,
  } as const

  function cleanNode(node: HTMLElement): HTMLElement | null {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const tagName = node.tagName.toLowerCase()

      // Remove disallowed tags
      if (!allowedTags.includes(tagName)) {
        return null
      }

      // Clean attributes
      const allowedAttrs = allowedAttributes[tagName] || []
      for (const attr of Array.from(node.attributes)) {
        const attrName = attr.name.toLowerCase()
        let attrValue = attr.value

        // Remove disallowed attributes
        if (!allowedAttrs.includes(attrName)) {
          node.removeAttribute(attrName)
          continue
        }

        // Remove attributes containing dangerous content
        if (
          /javascript\s*:/i.test(attrValue) ||
          /expression\s*\(/i.test(attrValue)
        ) {
          node.removeAttribute(attrName)
          continue
        }

        // Remove dangerous content in style attributes
        if (attrName === "style") {
          attrValue = sanitizeStyle(attrValue)
          if (!attrValue) {
            node.removeAttribute(attrName)
          } else {
            node.setAttribute(attrName, attrValue)
          }
        }
      }

      // Recursively clean child nodes
      const cleanedChildren: ChildNode[] = []
      for (const child of Array.from(node.childNodes)) {
        const cleanedChild = cleanNode(child as HTMLElement)
        if (cleanedChild) {
          cleanedChildren.push(cleanedChild)
        }
      }
      node.replaceChildren(...cleanedChildren)

      return node
    }

    if (node.nodeType === Node.TEXT_NODE) {
      return node
    }

    return null // Remove other types of nodes (e.g., comments)
  }

  // Sanitize style content
  function sanitizeStyle(style: string): string {
    // Patterns to detect dangerous content
    const dangerousPatterns = [
      /javascript\s*:/i,
      /expression\s*\(/i,
      /\\[0-9a-fA-F]{2,}/i, // Matches Unicode or hexadecimal-escaped characters
    ]

    // Decode escaped characters (if necessary)
    const decodedStyle = decodeEscapedCharacters(style)

    // Remove any matching dangerous patterns
    for (const pattern of dangerousPatterns) {
      if (pattern.test(decodedStyle)) {
        return "" // Remove the entire style if malicious content is detected
      }
    }
    return decodedStyle
  }

  // Function to decode escaped characters
  function decodeEscapedCharacters(style: string): string {
    return style.replace(/\\([0-9a-fA-F]{2,})/g, (_, hex) => {
      return String.fromCharCode(Number.parseInt(hex, 16))
    })
  }

  const cleanedChildren: ChildNode[] = []
  for (const child of Array.from(doc.body.childNodes)) {
    const cleanedChild = cleanNode(child as HTMLElement)
    if (cleanedChild) {
      cleanedChildren.push(cleanedChild)
    }
  }

  // Replace the body with cleaned children
  doc.body.replaceChildren(...cleanedChildren)

  return doc.body.innerHTML
}
